<template>
  <div class="relative pb-48">
    <van-cell v-if="check?.data">
      <template>
        <div class="mb-4">
          <h3>
            <van-tag type="primary" class="relative bottom-[2px]">
              <p>{{ formatTime(check?.data?.checkIn) }}</p>
            </van-tag>
            <span class="text-lg font-semibold">
              {{ check?.message }}
            </span>
          </h3>
        </div>
        <div class="w-full flex justify-between items-center gap-4">
          <div class="w-full">
            <van-steps
              :active-color="check?.late ? '#ff4400' : '#3aae47'"
              :active="!check.data.checkOut || check.data.checkOut == 'Invalid Date'"
              direction="vertical"
            >
              <van-step>
                <h4 class="font-bold">Check-in</h4>
                <p class="text-sm">{{ formatTime(check.data.checkIn, "DD MMM, HH:mm") }}</p>
              </van-step>
              <van-step>
                <h4 class="font-bold">Check-out</h4>

                <p v-if="check.late" class="">Lupa Checkout!</p>
                <p v-else class="text-sm">
                  {{
                    !check.data.checkOut || check.data.checkOut == "Invalid Date"
                      ? "Belum checkout"
                      : formatTime(check.data.checkOut, "DD MMM, HH:mm")
                  }}
                </p>
              </van-step>
            </van-steps>
          </div>
          <div class="aspect-square w-24 flex-shrink-0 text-center bg-slate-200 p-4 rounded-full">
            <v-icon :icon="check?.status == 'in' ? 'ph:sign-in' : 'ph:sign-out'" class="text-4xl mx-auto" />
            <p class="whitespace-nowrap">
              {{ check.status == "in" ? "In" : "Out" }}
            </p>
          </div>
        </div>
      </template>
    </van-cell>

    <van-cell v-else>
      <template>
        <div class="w-full flex justify-between items-center gap-4">
          <div class="w-full">
            <span class="text-lg font-semibold">
              {{ check?.message }}
            </span>
            <van-steps active-color="#aeaeae" :active="false" direction="vertical">
              <van-step>
                <h4 class="font-bold">Check-in</h4>
                <p class="text-sm">--</p>
              </van-step>
              <van-step>
                <h4 class="font-bold">Check-out</h4>

                <p class="text-sm">--</p>
              </van-step>
            </van-steps>
          </div>
          <div class="aspect-square w-24 flex-shrink-0 text-center bg-slate-200 p-4 rounded-full">
            <v-icon :icon="check?.status == 'in' ? 'ph:sign-in' : 'ph:sign-out'" class="text-4xl mx-auto" />
            <p class="whitespace-nowrap">
              {{ check?.status == "in" ? "In" : !check ? "Offline" : "Out" }}
            </p>
          </div>
        </div>
      </template>
    </van-cell>

    {{ selectedLocation }}
    <LocationForm
      title="Pilih lokasi absensi"
      @selected="
        (a) => {
          lokasi = a?.id;
          selectedLocation = a?.coords;
        }
      "
    />
    <PictureForm title="Ambil foto selfie" @selected="(a) => (fotoSelfie = a)" />
    <PictureForm title="Ambil foto sekitar" @selected="(a) => (fotoSekitar = a)" />

    <div class="fixed max-w-xl w-full bottom-0 bg-white px-2 py-4 flex items-end shadow-md shadow-primary">
      <div class="w-full pr-2">
        <div class="mb-2" v-if="check">
          <p>
            <span
              class="border-2 px-2 py-1 rounded animate-flashing relative"
              :class="
                check.status == 'in'
                  ? 'bg-green-400 border-green-600 text-green-600'
                  : 'bg-blue-400 border-blue-600 text-blue-600'
              "
            >
              {{ check?.status == "in" ? "Check-In" : "Check-Out" }}
            </span>
          </p>
          <p class="text-sm mt-2">{{ check?.message }}</p>
        </div>
        <p v-if="!allDone" class="text-sm text-danger">*Harap isi semua untuk mengirim</p>
      </div>

      <van-button
        :disabled="!allDone"
        :loading="isLoading"
        type="info"
        loading-type="spinner"
        @click="submit"
        class="whitespace-nowrap"
      >
        <v-icon icon="material-symbols:send" class="text-2xl mr-1 inline" />
        <span class="ml-2 font-bold">Simpan</span>
      </van-button>
    </div>
  </div>
</template>

<style scoped>
  .animate-flashing {
    animation: flashing 1s infinite;
  }

  @keyframes flashing {
    0% {
      --tw-bg-opacity: 1;
    }
    50% {
      --tw-bg-opacity: 0.1;
    }
    100% {
      --tw-bg-opacity: 1;
    }
  }
</style>

<script>
  import LocationForm from "@/components/Forms/LocationForm.vue";
  import PictureForm from "@/components/Forms/PictureForm.vue";
  import instance from "@/plugins/axios";

  import { location } from "@/states/location";
  import dayjs from "dayjs";
  import { users } from "@/states/user";
  // import { queueManager } from "@/states/local";

  import local from "@/states/local";
  import { deleteUpload } from "@/plugins/axios";

  export default {
    name: "AttendancePage",
    components: {
      PictureForm,
      LocationForm
    },

    data() {
      return {
        isLoading: false,
        check: null,
        lokasi: null,
        fotoSelfie: null,
        fotoSekitar: null,
        selectedLocation: null,
        tracking: location,
        local,
        users
      };
    },

    mounted() {
      instance.get("/attendance/@me/check").then((res) => {
        this.check = res.data;
      });
    },

    computed: {
      allDone() {
        return this.lokasi && this.fotoSelfie?.picture && this.fotoSekitar?.picture;
      }
    },

    methods: {
      async submit() {
        const toasty = this.$toast.loading({ message: "Mengupload foto selfie...", forbidClick: true, duration: 0 });
        this.isLoading = true;
        const selfie = await local.processFile(this.fotoSelfie.picture).catch((err) => {
          console.error(err);
          toasty.clear();
          this.$toast.fail({ message: "Gagal mengupload foto selfie", duration: 5000 });
          this.isLoading = false;
        });
        if (!selfie) return;
        toasty.message = "Mengupload foto sekitar...";
        const sekitar = await local.processFile(this.fotoSekitar.picture).catch((err) => {
          console.error(err);
          toasty.clear();
          this.$toast.fail({ message: "Gagal mengupload foto sekitar", duration: 5000 });
          this.isLoading = false;
        });
        if (!sekitar) return;
        toasty.message = "Mengirim data...";

        const coordCurrently = this.tracking;
        console.log(coordCurrently, this.tracking);

        local
          .processData("/attendance/assign", {
            location: this.lokasi,
            currentLocation: this.hasBypass()
              ? this.selectedLocation
              : [coordCurrently.latitude, coordCurrently.longitude],
            images: [selfie, sekitar],
            imageDescriptions: [this.fotoSelfie.description, this.fotoSekitar.description]
          })
          .then(() => {
            this.isLoading = false;
            toasty.clear();
            this.$router.go(-1);
          })
          .catch((err) => {
            this.isLoading = false;
            toasty.message = "Gagal mengirim data. Menghapus foto...";
            deleteUpload([selfie, sekitar])
              .then(() => {
                toasty.clear();
                this.$toast.fail({ message: "Gagal mengirim. Coba lagi nanti...", duration: 5000 });
              })
              .catch(() => {});

            console.error(err);
          });
      },
      hasBypass() {
        return this.users.data?.occupationPermission?.includes("explicitNoLocation");
      },
      formatTime(time, format = "HH:mm") {
        return dayjs(time).format(format);
      }
    }
  };
</script>
