<template>
  <div>
    <div class="w-full h-96 relative">
      <l-map class="w-full h-full z-10" :zoom="zoom" :center="center" :options="mapOptions">
        <l-tile-layer
          :url="'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'"
          :attribution="'&copy; OpenStreetMap contributors'"
        ></l-tile-layer>
        <l-circle :lat-lng="center" :radius="radius" :color="'transparent'" :fill-opacity="0.5"></l-circle>
        <template v-for="point in pointsMapped">
          <div :key="point.id">
            <l-circle
              :lat-lng="point.coords"
              :radius="point.radius"
              :color="'transparent'"
              :fill-color="point.isInside ? '#56f' : '#f56'"
              :fill-opacity="0.2"
            ></l-circle>
            <l-marker :lat-lng="point.coords" :interactive="false">
              <l-icon class="relative">
                <v-icon
                  icon="ph:target"
                  class="-bottom-3 -left-3 absolute text-4xl"
                  :class="point.isInside ? 'text-primary' : 'text-danger'"
                />
                <div
                  class="bg-white whitespace-nowrap absolute left-1 top-1 px-2 py-1 rounded-tl-none rounded-md shadow"
                >
                  <div class="text-sm">
                    <p class="">{{ point.name }}</p>
                  </div>
                </div>
              </l-icon>
            </l-marker>
          </div>
        </template>
        <l-marker :lat-lng="center" :interactive="false">
          <l-icon class="relative">
            <div
              class="bg-white border-2 border-primary whitespace-nowrap absolute left-1 top-1 px-2 py-1 rounded-tl-none rounded-md shadow"
            >
              <div class="text-sm">
                <p class="font-bold">Anda Disini</p>
                <p class="text-xs">Radius: {{ radius?.toFixed(2) || "??" }}m</p>
              </div>
            </div>
          </l-icon>
        </l-marker>
      </l-map>
      <div class="absolute z-50 bottom-0 right-0 flex gap-2 flex-col p-2">
        <button v-on:click="() => (zoom += 1)" class="bg-white text-primary shadow p-2 rounded-full">
          <v-icon icon="ph:plus" class="text-xl" />
        </button>
        <button v-on:click="() => (zoom -= 1)" class="bg-white text-primary shadow p-2 rounded-full">
          <v-icon icon="ph:minus" class="text-xl" />
        </button>
      </div>
    </div>
    <div class="grid gap-2 sm:grid-cols-2 mt-2">
      <div v-if="pointsMapped.length == 0" class="bg-danger rounded relative py-4 px-2 text-white overflow-hidden">
        <p class="text-center font-bold">Tidak ada lokasi</p>
        <p class="text-center text-sm">Silahkan hubungi admin</p>
        <v-icon
          icon="ph:warning"
          class="block rotate-6 text-center -top-2 opacity-50 -left-2 absolute"
          style="font-size: 6em"
        />
      </div>
      <div
        v-if="pointsMapped.filter((x) => x.isInside).length == 0"
        class="rounded relative py-4 px-2 col-span-2 overflow-hidden"
      >
        <p class="text-center font-bold">Tidak ada lokasi terdekat</p>
        <p class="text-center text-sm">Harap berjalan ke titik lokasi terdekat</p>
        <v-icon
          icon="ph:warning"
          class="block rotate-6 text-center -top-2 opacity-50 -left-2 absolute"
          style="font-size: 6em"
        />
      </div>
      <van-cell-group>
        <!-- 
        1 || 1
        0 ||
        -->
        <van-cell
          v-for="point in pointsMapped"
          :key="point.id"
          :disabled="!selectable ? !point.isInside : false"
          :title="point.name"
          is-link
          :value="point.isInside ? '' : 'Outside!'"
          :label="point.distance.toFixed(2) + ' Meter away'"
          @click="() => (selectable || point.isInside) && selectPoint(point)"
        >
          <template>
            <van-tag
              size="medium"
              round
              :type="
                selectable ? (point.id == selectedPoint ? 'primary' : 'default') : point.isInside ? 'success' : 'danger'
              "
              >{{
                selectable ? (point.id == selectedPoint ? "Selected!" : "Select") : point.isInside ? "Ok!" : "Diluar!"
              }}</van-tag
            >
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
  /* eslint-disable */
  import { LMap, LTileLayer, LCircle, LMarker, LIcon } from "vue2-leaflet";
  import "leaflet/dist/leaflet.css";
  import { location, getLocation } from "@/states/location";

  export default {
    name: "MapComp",
    props: {
      selectable: {
        type: Boolean,
        default: false
      },
      points: {
        type: Array,
        default: null
      },
      bypass: {
        type: Boolean,
        default: false
      }
    },
    emits: ["selected"],
    data() {
      return {
        geoLocation: { latitude: 0, longitude: 0, accuracy: 0 },
        zoom: 10,
        mapOptions: {
          zoomControl: false,
          attributionControl: false,
          controlLayers: false
        },
        center: [0, 0],
        selectedPoint: null
      };
    },
    mounted() {
      getLocation();
      this.geoLocation = location;
    },
    computed: {
      radius() {
        return this.geoLocation?.accuracy;
      },
      pointsMapped() {
        if (!this.points) return [];
        const points = this.points
          .map((point) => {
            const distance = Haversine(this.center[0], this.center[1], point.coords[0], point.coords[1]);
            return {
              ...point,
              distance,
              isInside: distance < point.radius
            };
          })
          // by is inside and distance
          .sort((a, b) => {
            if (a.isInside && !b.isInside) return -1;
            if (!a.isInside && b.isInside) return 1;
            return a.distance - b.distance;
          });
        return points;
      }
    },
    watch: {
      geoLocation: {
        handler(val) {
          console.log(val, "VAL");
          const coords = isFinite(val.latitude)
            ? val
            : {
                latitude: 0,
                longitude: 0
              };

          this.center = [coords.latitude, coords.longitude];

          // console.log(typeof val.find, "VAL");

          if (typeof val.find == "undefined") return console.log("VAL");

          const selectedPoint = val.find((point) => point.isInside);
          if (!this.selectable) {
            this.selectedPoint = selectedPoint?.id;
            console.log(selectedPoint, "HIT!");

            if (selectedPoint) this.$emit("selected", selectedPoint);
          }
        },
        deep: true
      },
      zoom: {
        handler(val) {
          this.zoom = val < 1 ? 1 : val > 18 ? 18 : val;
        },
        deep: true
      },
      pointsMapped: {
        handler(val) {
          if (!val) return;
          const selectedPoint = val.find((point) => point.isInside);
          if (!this.selectable) {
            this.selectedPoint = selectedPoint?.id;
            console.log(selectedPoint, "HIT!");

            if (selectedPoint) this.$emit("selected", selectedPoint);
          }
        },
        deep: true
      }
    },
    components: {
      LMap,
      LTileLayer,
      LCircle,
      LMarker,
      LIcon
    },
    methods: {
      Haversine,
      selectPoint(point) {
        this.selectedPoint = point.id;
        this.$emit("selected", point);
      }
    }
  };

  function Haversine(lat1, lon1, lat2, lon2) {
    const R = 6371e3;
    const latitudeInRadians = (lat1 * Math.PI) / 180;
    const latitudeToRadian2 = (lat2 * Math.PI) / 180;
    const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(latitudeInRadians) * Math.cos(latitudeToRadian2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c;

    return d;
  }
</script>
