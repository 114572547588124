<template>
  <div class="z-50">
    <template v-for="dat in data">
      <LegalReminderComp :key="dat.id" :legal="dat" :members="columns" @closed="closed" />
    </template>
    <template v-for="dat in follup">
      <LegalFollowUpAcknowledgeComp :key="dat.id" :legal="dat" @closed="closedFollowUp" />
    </template>
  </div>
</template>

<script>
  import instance from "@/plugins/axios";
  import { users } from "@/states/user";
  import LegalReminderComp from "./LegalReminderComp.vue";
  import LegalFollowUpAcknowledgeComp from "./LegalFollowUpAcknowledgeComp.vue";

  export default {
    data: () => ({
      data: [],
      follup: [],
      users,
      columns: []
    }),
    components: {
      LegalReminderComp,
      LegalFollowUpAcknowledgeComp
    },
    mounted() {
      const ws = this.users.data?.workSite;

      if (this.users.data?.occupationPermission?.includes?.("explicitLegalJudge"))
        instance
          .get(`/legal/alert`)
          .then((res) => {
            this.data = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      else if (this.users.data?.occupationPermission?.includes?.("explicitLegalInvolvement"))
        instance
          .get(`/group/${ws}/legal-reminder/alert`)
          .then((res) => {
            this.data = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    watch: {
      users: {
        handler(newVal) {
          const ws = newVal.data?.workSite;

          if (newVal.data?.occupationPermission?.includes?.("explicitSpecialEdKid")) {
            instance
              .get(`/legal/alert/reports`)
              .then((res) => {
                this.follup = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          }

          if (
            newVal.data?.occupationPermission?.includes?.("explicitLegalInvolvement") ||
            newVal.data?.occupationPermission?.includes?.("explicitLegalJudge")
          )
            instance
              .get(`/group/${ws}/members`)
              .then((res) => {
                this.columns = res.data.map((i) => ({
                  text: `${i.fullName} (${i.username})`,
                  value: i.id
                }));
              })
              .then(() => {
                if (newVal.data?.occupationPermission?.includes?.("explicitLegalJudge"))
                  instance
                    .get(`/legal/alert`)
                    .then((res) => {
                      this.data = res.data;
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                else if (newVal.data?.occupationPermission?.includes?.("explicitLegalInvolvement"))
                  instance
                    .get(`/group/${ws}/legal-reminder/alert`)
                    .then((res) => {
                      this.data = res.data;
                    })
                    .catch((err) => {
                      console.log(err);
                    });
              });
        },
        deep: true
      }
    },

    methods: {
      closed(id) {
        this.data = this.data.filter((i) => i.id !== id);
      },
      closedFollowUp(id) {
        this.follup = this.follup.filter((i) => i.id !== id);
      }
    }
  };
</script>
