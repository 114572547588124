import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Icon } from "@iconify/vue2";
import VCalendar from "v-calendar";
import { getLocation, watchLocation } from "@/states/location";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

import Vant from "vant";
import "vant/lib/index.css";

dayjs.extend(utc);
dayjs.extend(relativeTime);

getLocation();
watchLocation();

Vue.component("v-icon", Icon);
Vue.use(VCalendar, {
  componentPrefix: "vc"
});

Vue.use(Vant);

import "./assets/css/base.css";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
