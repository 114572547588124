<template>
  <div class="z-50">
    <!-- Popup Date -->
    <van-popup v-model="options.date" position="bottom" round>
      <van-datetime-picker
        type="date"
        title="Tanggal"
        :min-date="new Date()"
        confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        :formatter="pickerFormat"
        @confirm="dateConfirm"
        @cancel="dateCancel"
      />
    </van-popup>
    <!-- Popup Date R -->
    <van-popup v-model="options.finished.release" position="bottom" round>
      <van-datetime-picker
        type="date"
        title="Tanggal Terbit"
        confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        :formatter="pickerFormat"
        @confirm="dateRlConfirm"
        @cancel="dateRlCancel"
      />
    </van-popup>
    <!-- Popup Date E -->
    <van-popup v-model="options.finished.expired" position="bottom" round>
      <van-datetime-picker
        type="date"
        title="Tanggal Berakhir"
        :min-date="new Date()"
        confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        :formatter="pickerFormat"
        @confirm="dateExConfirm"
        @cancel="dateExCancel"
      />
    </van-popup>

    <!-- Popup PIC -->
    <van-popup v-model="options.pic" position="bottom" round>
      <van-picker
        show-toolbar
        title="Title"
        :columns="members"
        confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        @confirm="picConfirm"
        @cancel="picCancel"
      />
    </van-popup>

    <!-- Popup Follow-Up -->
    <van-dialog
      v-model="options.followUp"
      title="Formulir Follow-up"
      show-cancel-button
      confirm-button-text="Selesai"
      cancel-button-text="Gak Jadi"
      @submit="submitFollowUp"
      @confirm="submitFollowUp"
    >
      <van-cell-group class="mt-4">
        <van-field label="PIC" :value="inputted?.pic?.text || '(Pilih)'" readonly @click="options.pic = true" />
        <van-field
          label="Tanggal"
          :value="inputted?.date ? dateFormat(inputted?.date, 'DD MMM YYYY') : '(Pilih)'"
          readonly
          @click="options.date = true"
        />
      </van-cell-group>
    </van-dialog>

    <!-- dialong to fill the form -->
    <!-- 
      Name :
      No. Dokumen :
      Tanggal Terbit :
      Tanggal Berakhir :
      Keterangan :
      Document URL :
      082321941996
     -->

    <van-dialog
      v-model="options.finish"
      title="Formulir Selesai"
      show-cancel-button
      confirm-button-text="Selesai"
      cancel-button-text="Gak Jadi"
      @submit="finishIt"
      @confirm="finishIt"
    >
      <van-cell-group class="mt-4">
        <van-field label="Nama " v-model="finishedInput.documentName" />
        <van-field label="No. Dok" v-model="finishedInput.documentNumber" />
        <van-field
          label="Terbit"
          :value="finishedInput?.releaseDate ? dateFormat(finishedInput?.releaseDate, 'DD MMM YYYY') : '(Pilih)'"
          readonly
          @click="options.finished.release = true"
        />

        <van-field
          label="Kadaluarsa"
          :value="finishedInput?.expiredDate ? dateFormat(finishedInput?.expiredDate, 'DD MMM YYYY') : '(Pilih)'"
          readonly
          @click="options.finished.expired = true"
        />
        <van-field label="Keterangan" v-model="finishedInput.notes" />
        <van-field label="Document URL" v-model="finishedInput.documentURL" />
      </van-cell-group>
    </van-dialog>

    <!-- Main -->

    <van-overlay :show="true" :lock-scroll="true">
      <div class="bg-red-900 w-full h-full overflow-y-auto overflow-x-hidden pt-10 relative flex flex-col">
        <div class="h-fit">
          <v-icon
            icon="material-symbols:warning-outline"
            width="196"
            class="mr-1 inline absolute -right-12 opacity-15 -rotate-6 -top-2 text-red-200"
          />
          <div class="text-white p-4">
            <h1 class="text-2xl font-bold">Peringatan Legal!</h1>
            <table class="mt-2">
              <tr>
                <td class="pr-4">Cabang</td>
                <td class="font-bold">{{ legal?.groupName }}</td>
              </tr>
              <tr>
                <td class="pr-4">Nama Dokumen</td>
                <td class="font-bold">{{ legal?.documentName }}</td>
              </tr>
              <tr>
                <td class="pr-4">No. Dokumen</td>
                <td class="font-bold">{{ legal?.documentNumber }}</td>
              </tr>
              <tr>
                <td>Tanggal Terbit</td>
                <td class="font-bold">
                  {{ legal?.releaseDate ? dateFormat(legal?.releaseDate, "DD MMM YYYY, HH:mm") : "-" }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="bg-white h-fit px-4 py-2 text-center border-b-2 border-red-900 border-dashed">
          <p class="text-sm">Dokumen ini akan berakhir pada</p>
          <p class="text-lg font-bold">
            <v-icon icon="material-symbols:alarm-outline" class="text-xl mr-1 mb-1 inline" />
            <span> {{ legal?.expiredDate ? relativeTime(legal?.expiredDate) : "-" }} </span>
          </p>
          <p class="text-sm">({{ legal?.expiredDate ? dateFormat(legal?.expiredDate, "DD MMM YYYY") : "-" }})</p>
        </div>

        <div class="px-4 py-4 bg-white h-fit">
          <label>Keterangan</label>
          <van-field
            v-model="message"
            rows="2"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="Tulis keterangan disini... "
            show-word-limit
          />
        </div>

        <!-- comment-like section card (not-field) -->
        <div class="py-2 pb-4 bg-white relative h-full">
          <div class="flex gap-2 px-4 py-2 bg-white">
            <v-icon icon="material-symbols:comment-outline" class="text-xl" />
            <span class="text-sm">Hasil Report Foll-up</span>
          </div>
          <div class="p-2 px-4 h-full">
            <div class="flex gap-2 mb-4 bg-gray-100 rounded mt-2 px-2 py-2" v-for="(report, i) in reports" :key="i">
              <v-icon icon="material-symbols:account-circle" class="text-xl" />
              <div class="w-full">
                <p class="float-right">
                  <span class="text-xs mr-1">{{ dateFormat(report.createdAt, "DD MMM YYYY") }}</span>
                  <v-icon
                    v-if="report.followedByFullName"
                    icon="material-symbols:check-circle"
                    class="text-green-700 inline"
                  />
                  <v-icon v-else icon="material-symbols:cancel" class="text-red-700 inline" />
                </p>
                <p class="font-bold">{{ report.fullName }}</p>
                <p class="text-sm">{{ report.notes }}</p>
                <div class="mt-2 text-xs" v-if="report.followedByFullName">
                  <span>Followed by </span>
                  <v-icon icon="material-symbols:arrow-right-alt" class="text-lg inline mx-1" />
                  <span class="font-bold text-green-700">{{ report.followedByFullName }}</span>
                  <span> on </span>
                  <span class="font-bold text-green-700">{{ dateFormat(report.followedDate, "DD MMM YYYY") }}</span>
                </div>
                <div v-else class="mt-2 text-xs">
                  <!-- Rejection -->
                  <span class="text-red-700">Rejected</span>
                  <span> on </span>
                  <span class="font-bold text-red-700">{{ dateFormat(report.createdAt, "DD MMM YYYY") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 w-full p-4 pt-2 bg-white h-fit">
          <div v-if="isFiveDay">
            <p class="text-sm text-red-700 mb-2">
              *Dokumen ini sudah melewati 5 hari dari Follow-Up. Harap di lanjut untuk melanjutkan aktifitas.
            </p>
          </div>
          <div class="flex gap-2">
            <button v-if="message" type="info" @click="options.finish = true" class="button">Done</button>
            <!-- Confirm selesai?
            (yes) Done Archive 
            -->

            <div class="w-full"></div>
            <button type="primary" v-if="message" @click="options.followUp = true" class="button">Follow-Up</button>
            <!-- PIC? Tanggal Follow Up? -->

            <button
              type="info"
              :disabled="isFiveDay"
              @click="closeThis"
              class="button"
              :class="isFiveDay && 'opacity-50'"
            >
              Abaikan
            </button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<style scoped>
  .button {
    @apply bg-blue-800 rounded py-2 px-4 text-white  whitespace-nowrap;
  }
</style>

<script>
  import { Dialog } from "vant";
  import dayjs from "dayjs";
  import instance from "@/plugins/axios";
  import { users } from "@/states/user";

  export default {
    data: () => ({
      message: "",
      options: {
        pic: false,
        date: false,
        finished: {
          release: false,
          expired: false
        },
        followUp: false,
        finish: false
      },
      inputted: {
        pic: null,
        date: null
      },
      finishedInput: {
        documentName: "",
        documentNumber: "",
        releaseDate: "",
        expiredDate: "",
        notes: "",
        documentURL: ""
      },
      dayjs,
      users,
      reports: []
    }),
    props: ["legal", "members"],

    mounted() {
      this.finishedInput = {
        documentName: this.legal.documentName,
        documentNumber: this.legal.documentNumber,
        releaseDate: this.legal.releaseDate,
        expiredDate: this.legal.expiredDate,
        notes: this.legal.notes,
        documentURL: this.legal.documentURL
      };
      instance
        .get(`/legal/${this.legal.id}/reports`)
        .then((res) => {
          this.reports = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    computed: {
      isFiveDay() {
        // lock if true
        const isFiveDay = dayjs(this.legal.reminderFollowed).diff(dayjs(), "day") <= 5;
        const isExplicit = !this.users.data.occupationPermission.includes("explicitLegalJudge");

        // lock if been five day and not explicit
        return isFiveDay && isExplicit;
      }
    },

    methods: {
      finishIt() {
        Dialog.confirm({
          title: "Selesai?",
          message:
            "Apakah kamu yakin dokumen ini telah diselesaikan atau diperpanjang? Ini akan dimasukan ke dalam arsip.",
          cancelButtonText: "Belum yakin",
          confirmButtonText: "Iya"
        })
          .then(() => {
            Dialog.confirm({
              title: "Konfirmasi lagi",
              message: "Apakah kamu yakin? Hal ini tidak bisa diubah lagi.",
              cancelButtonText: "Tidak",
              confirmButtonText: "Iya"
            })
              .then(() => {
                this.submitFinish();
              })
              .catch(() => {
                // on cancel
              });
          })
          .catch(() => {
            // on cancel
          });
      },
      closeThis() {
        this.$emit("closed", this.legal.id);
      },
      submitFollowUp() {
        // submit follow up
        instance
          .post(`/legal/${this.legal.id}/reports/add`, {
            notes: this.message,
            followedBy: this.inputted.pic?.value,
            followedDate: this.inputted.date
          })
          .then(() => {
            this.closeThis();
          })
          .catch((err) => {
            this.$toast.fail(err.response.data.message);
          });
      },
      submitFinish() {
        // submit finish
        instance
          .post(`/legal/${this.legal.id}/finish`, {
            documentName: this.finishedInput.documentName,
            documentNumber: this.finishedInput.documentNumber,
            releaseDate: this.finishedInput.releaseDate,
            expiredDate: this.finishedInput.expiredDate,
            notes: this.finishedInput.notes,
            documentURL: this.finishedInput.documentURL
          })
          .then(() => {
            this.closeThis();
          })
          .catch((err) => {
            this.$toast.fail(err.response.data.message);
          });
      },
      picConfirm(e) {
        this.inputted.pic = e;
        this.options.pic = false;
      },
      picCancel() {
        this.options.pic = false;
      },
      dateConfirm(e) {
        this.inputted.date = e;
        this.options.date = false;
      },
      dateCancel() {
        this.options.date = false;
      },
      dateRlConfirm(e) {
        this.finishedInput.releaseDate = e;
        this.options.finished.release = false;
      },
      dateRlCancel() {
        this.options.finished.release = false;
      },
      dateExConfirm(e) {
        this.finishedInput.expiredDate = e;
        this.options.finished.expired = false;
      },
      dateExCancel() {
        this.options.finished.expired = false;
      },
      dateFormat(time, format) {
        return dayjs(time).format(format);
      },

      relativeTime(time) {
        // 5 months 2 day
        const diff = dayjs(time).diff(dayjs(), "day");
        if (diff < 0) return "Expired";
        const months = Math.floor(diff / 30);
        const days = diff % 30;
        return `${months} bulan ${days} hari`;
      },

      pickerFormat(type, val) {
        if (type === "month") {
          // into localization (Jan, Feb...) using day js
          return dayjs()
            .month(val - 1)
            .format("MMMM");
        }
        return val;
      }
    }
  };
</script>
