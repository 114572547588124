<template>
  <div class="bg-white shadow-md rounded p-2 text-black">
    <van-popup v-model="toggle" round position="bottom">
      <CameraComp
        v-if="toggle"
        @photo="
          (a) => {
            picture = a;
            toggle = false;
          }
        "
      />
    </van-popup>
    <div class="flex gap-4 items-start">
      <div class="py-2 w-full relative">
        <van-skeleton v-if="user.loading" title :row="2" />
        <div class="pl-2" v-else>
          <p class="text-lg">
            <strong>
              {{ user.data.fullName }}
            </strong>
          </p>
          <p class="opacity-60">{{ user.data.username }}</p>
          <p class="font-bold">{{ user.data.occupationName || "N/A" }}</p>
        </div>
      </div>
      <div class="flex-shrink-0 w-20 h-20 rounded-2xl overflow-hidden" @click="toggle = true">
        <van-image fit="cover" :src="pictureUrl" width="5rem" height="5rem" />
      </div>
    </div>
    <hr class="mb-2" />
    <div class="flex justify-between">
      <div class="w-full">
        <van-skeleton v-if="user.loading" :row="1" />
        <div v-else>
          <p class="text-sm">Mulai Kerja</p>
          <p class="font-bold">{{ timeParse(user.data.startDate) }}</p>
        </div>
      </div>
      <div class="w-full">
        <van-skeleton v-if="user.loading" :row="1" />
        <div class="text-right" v-else>
          <p class="text-sm">Tempat Kerja</p>
          <p class="font-bold">{{ user.data.workSiteName }}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <div v-if="containQueue" class="w-full">
        <p class="text-sm">Kamu mempunyai {{ containQueue }} data offline</p>
      </div>
      <div v-else class="w-full text-right">
        <van-button
          size="small"
          type="danger"
          loading-type="spinner"
          @click="logout"
          class="whitespace-nowrap text-md mt-1"
        >
          <v-icon icon="material-symbols:logout" class="text-xl mr-1 inline" />
          <span class="ml-1 font-bold">Logout</span>
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { users } from "@/states/user";
  import dayjs from "dayjs";
  import CameraComp from "@/components/Inputs/CameraComp.vue";
  import local from "@/states/local";
  export default {
    name: "UserDetailsComp",
    components: {
      CameraComp
    },
    data() {
      return {
        user: users,
        toggle: false,
        picture: null,
        dataQueue: local.dataQueue
      };
    },
    mounted() {},

    methods: {
      timeParse(timestamp) {
        return dayjs(timestamp).format("DD MMM YYYY");
      },
      logout() {
        window.localStorage.removeItem("token");
        this.$router.push("/");
      },
      offlineSync() {
        local.uploadQueue();
      }
    },
    computed: {
      pictureUrl() {
        if (!this.picture) return "/kyuiki.png";
        return URL.createObjectURL(this.picture);
      },
      containQueue() {
        return this.dataQueue.length;
      }
    }
  };
</script>
