<template>
  <div class="z-50">
    <!-- Main -->

    <van-overlay :show="true" :lock-scroll="true">
      <div class="bg-amber-700 w-full h-full overflow-y-auto overflow-x-hidden pt-10 relative flex flex-col">
        <div class="h-fit">
          <v-icon
            icon="material-symbols:warning-outline"
            width="196"
            class="mr-1 inline absolute -right-12 opacity-15 -rotate-6 -top-2 text-red-200"
          />
          <div class="text-white p-4">
            <h1 class="text-2xl font-bold">Alert Follow Up!</h1>
            <table class="mt-2">
              <tr>
                <td class="pr-4">Cabang</td>
                <td class="font-bold">{{ legal?.groupName }}</td>
              </tr>
              <tr>
                <td class="pr-4">Nama Dokumen</td>
                <td class="font-bold">{{ legal?.documentName }}</td>
              </tr>
              <tr>
                <td class="pr-4">No. Dokumen</td>
                <td class="font-bold">{{ legal?.documentNumber }}</td>
              </tr>
              <tr>
                <td>Tanggal Terbit</td>
                <td class="font-bold">
                  {{ legal?.releaseDate ? dateFormat(legal?.releaseDate, "DD MMM YYYY, HH:mm") : "-" }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="bg-white h-fit px-4 py-2 text-center border-b-2 border-red-900 border-dashed">
          <p class="text-sm">Dokumen ini akan berakhir pada</p>
          <p class="text-lg font-bold">
            <v-icon icon="material-symbols:alarm-outline" class="text-xl mr-1 mb-1 inline" />
            <span> {{ legal?.expiredDate ? relativeTime(legal?.expiredDate) : "-" }} </span>
          </p>
          <p class="text-sm">({{ legal?.expiredDate ? dateFormat(legal?.expiredDate, "DD MMM YYYY") : "-" }})</p>
        </div>

        <!-- comment-like section card (not-field) -->
        <div class="py-2 pb-4 bg- relative h-full">
          <div class="p-2 px-4 h-full">
            <div class="flex gap-2 mb-4 bg-gray-100 rounded mt-2 px-2 py-2">
              <v-icon icon="material-symbols:account-circle" class="text-xl" />
              <div class="w-full">
                <p class="float-right">
                  <span class="text-xs mr-1">{{ dateFormat(legal.createdAt, "DD MMM YYYY") }}</span>
                  <v-icon
                    v-if="legal.followedByFullName"
                    icon="material-symbols:check-circle"
                    class="text-green-700 inline"
                  />
                  <v-icon v-else icon="material-symbols:cancel" class="text-red-700 inline" />
                </p>
                <p class="font-bold">{{ legal.fullName }}</p>
                <p class="text-sm">{{ legal.notes }}</p>
                <div class="mt-2 text-xs" v-if="legal.followedByFullName">
                  <span>Followed by </span>
                  <v-icon icon="material-symbols:arrow-right-alt" class="text-lg inline mx-1" />
                  <span class="font-bold text-green-700">{{ legal.followedByFullName }}</span>
                  <span> on </span>
                  <span class="font-bold text-green-700">{{ dateFormat(legal.followedDate, "DD MMM YYYY") }}</span>
                </div>
                <div v-else class="mt-2 text-xs">
                  <!-- Rejection -->
                  <span class="text-red-700">Rejected</span>
                  <span> on </span>
                  <span class="font-bold text-red-700">{{ dateFormat(legal.createdAt, "DD MMM YYYY") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 w-full p-4 pt-2 bg-white h-fit">
          <div class="flex gap-2">
            <button type="info" @click="closeThis" class="button">Abaikan</button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<style scoped>
  .button {
    @apply bg-blue-800 rounded py-2 px-4 text-white  whitespace-nowrap;
  }
</style>

<script>
  import dayjs from "dayjs";
  import { users } from "@/states/user";

  export default {
    data: () => ({
      message: "",
      dayjs,
      users,
      reports: []
    }),
    props: ["legal"],

    mounted() {},

    methods: {
      closeThis() {
        this.$emit("closed", this.legal.id);
      },
      dateFormat(date, format) {
        return this.dayjs(date).format(format);
      },
      relativeTime(date) {
        return this.dayjs(date).fromNow();
      }
    }
  };
</script>
