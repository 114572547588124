<template>
  <div class="py-4">
    <van-image-preview v-model="show" :images="images" @change="onChange">
      <template v-slot:index>Page: {{ index }}</template>
    </van-image-preview>
    <div class="p-2">
      <router-link to="/dashboard/report/patrol/new" class="bg-primary px-4 py-2 rounded flex float-right">
        <v-icon icon="material-symbols:add" class="text-2xl text-white mr-2" />
        <span class="text-white">Buat</span>
      </router-link>
      <h1 class="text-2xl font-bold">Laporan Patroli</h1>
      <p>Laporan patroli yang telah dibuat oleh pengguna.</p>
    </div>

    <van-collapse v-model="activeAccordion" v-if="online">
      <van-collapse-item v-for="date in dailyReports" :key="date.date">
        <template #title>
          <span class="text-left">{{ date.date }}</span>
        </template>
        <template #default>
          <van-list>
            <!-- v-for="report in date.reports" :key="report.id" -->
            <van-cell>
              <template>
                <div class="w-full">
                  <van-steps
                    :active-color="'#000'"
                    :active="sortByLocations(date.reports).length - 1"
                    direction="vertical"
                  >
                    <template v-for="po in sortByLocations(date.reports)">
                      <van-step :key="po.id">
                        <p class="float-right">{{ po.reports?.length }} laporan</p>
                        <h4 class="font-bold">{{ po.location.name }}</h4>

                        <template v-for="report in po.reports">
                          <div :key="report.id" class="py-2 border-b-2 border-gray-200 rounded my-2">
                            <p>
                              <span class="text-sm font-bold float-right">{{ iconParse(report.createdAt) }} </span>
                              <span class="">{{ report.reportTitle }}</span>
                            </p>
                            <p class="text-sm">{{ report.reportContent }}</p>
                            <div class="flex">
                              <img
                                v-for="(c, i) in report.reportImages"
                                :key="i"
                                :src="c"
                                @click="openPreview(report.reportImages)"
                                alt=""
                                v-lazy="c"
                                class="w-12 aspect-square object-cover ml-2"
                              />
                            </div>
                          </div>
                        </template>
                      </van-step>
                    </template>
                  </van-steps>
                </div>
              </template>
            </van-cell>
          </van-list>
        </template>
      </van-collapse-item>
    </van-collapse>

    <div class="border-2 border-red-400 rounded m-2 px-2 py-1 bg-red-100" v-else>
      Kamu offline. Tidak dapat menampilkan data
    </div>
  </div>
</template>
<script>
  import dayjs from "dayjs";
  import axios from "@/plugins/axios";
  import { pointLocations, fetchPointLocations } from "@/states/user";

  import { online } from "@/states/local";

  export default {
    data() {
      return {
        reports: [],
        online,
        showPreview: false,
        activeAccordion: [],
        pointLocations
      };
    },
    components: {},
    computed: {
      dailyReports() {
        return this.sortReportsByTimestamp();
      },
      points() {
        return pointLocations.data.map((x) => ({
          id: x.id,
          name: x.name,
          lat: x.lat,
          lng: x.lng
        }));
      }
    },
    mounted() {
      fetchPointLocations();
      axios
        .get("/report/patrol/@me")
        .then((res) => {
          const report = res.data;
          this.reports = report;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    methods: {
      sortReportsByTimestamp() {
        let dailyReport = [];
        this.reports
          .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
          .forEach((report) => {
            const reportDate = dayjs(report.createdAt).format("YYYY-MM-DD");
            const existingReport = dailyReport.find((r) => r.date === reportDate);
            if (existingReport) {
              existingReport.reports.push(report);
            } else {
              dailyReport.push({ date: reportDate, reports: [report] });
            }
          });
        return dailyReport;
      },
      sortByLocations(reports) {
        const locations = reports.map((x) => x.locationId);
        const uniqueLocations = [...new Set(locations)];
        return uniqueLocations.map((location) => ({
          location: this.points.find((x) => x.id === location),
          reports: reports.filter((x) => x.locationId === location)
        }));
      },
      iconParse(date) {
        const day = dayjs(date).format("HH:mm");
        return day;
      }
    }
  };
</script>
import { readonly } from "vue";
