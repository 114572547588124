<template>
  <div class="grid grid-cols-3 gap-2 text-md">
    <router-link
      class="text-center p-2"
      v-for="item in consideredFeatures.filter((x) =>
        searchFilter ? x.title.toLowerCase().includes(searchFilter.toLowerCase()) : true
      )"
      :key="item.title"
      :to="item.path"
    >
      <div class="mx-auto bg-opacity-50 rounded-full h-16 w-16 sm:w-20 sm:h-20 text-center p-2 sm:p-4 duration-300">
        <!-- <v-icon name="MdBookmarkSharp" /> -->
        <v-icon :icon="item.icon" class="text-5xl" :class="item.forManager ? 'text-accent' : 'text-primary'" />
        <!-- <img v-bind:src="item.icon" alt="placeholder" class="mx-auto" /> -->
      </div>
      <span>{{ item.title }}</span>
    </router-link>
  </div>
</template>

<script>
  import { users } from "@/states/user";

  export default {
    name: "FeatureItemsComp",
    props: {
      searchFilter: {
        type: String
      }
    },
    data() {
      return {
        items: [
          {
            title: "Mulai Absen",
            icon: "ph:clock-duotone",
            description: "Mulai Absensi",
            path: "/dashboard/attendance/new",
            need: "attendance"
          },
          {
            title: "Mulai Laporan Harian",
            icon: "ph:clipboard-duotone",
            description: "Mulai Laporan Harian",
            path: "/dashboard/report/daily/new",
            need: "report"
          },
          {
            title: "Overtime",
            icon: "ph:calendar-dots-duotone",
            description: "Overtime",
            path: "/dashboard/overtime",
            need: "overtime"
          },
          {
            title: "Ringkasan Laporan Harian",
            icon: "ph:user-list-duotone",
            description: "Absensi karyawan.",
            path: "/dashboard/report/daily",
            need: "report"
          },
          {
            title: "Ringkasan Kehadiran",
            icon: "ph:calendar-check-duotone",
            description: "Absensi karyawan.",
            path: "/dashboard/attendance",
            need: "attendance"
          },
          {
            title: "Mulai Patroli",
            icon: "ph:user-list",
            description: "Patroli.",
            path: "/dashboard/report/patrol/new",
            need: "patrol"
          },
          {
            title: "Ringkasan Laporan Patroli",
            icon: "ph:user-list-duotone",
            description: "Absensi karyawan.",
            path: "/dashboard/report/patrol",
            need: "patrol"
          },
          {
            title: "Slip Gaji",
            icon: "ph:invoice",
            description: "Generate slip gaji karyawan.",
            path: "/dashboard/payroll",
            need: "slip"
          },
          {
            title: "Monitor Kehadiran",
            icon: "ph:clock-duotone",
            description: "Monitor Kehadiran",
            path: "/dashboard/attendance/monitor",
            forManager: true,
            needPermission: "readAttendance"
          },
          {
            title: "Legalitas",
            icon: "ph:shield-duotone",
            description: "Monitor Kehadiran",
            path: "/dashboard/legal",
            forManager: true,
            needPermission: "explicitLegalInvolvement"
          }
        ],
        users: users
      };
    },
    computed: {
      consideredFeatures() {
        return this.items
          .filter((x) => !x.need || this.users.data?.occupationMenus?.includes(x.need))
          .filter((x) => !x.needPermission || this.users.data?.occupationPermission?.includes(x.needPermission));
      }
    }
  };
</script>
